import {
  mapActions, mapGetters, mapMutations,
} from 'vuex';

import {
  Challenge,
  Sidebar,
  Map,
  Activity,
  UserCard,
  GroupFollowersAdmin,
  ShareModal,
  Advertisement,
} from '@/components';

export default {
  metaInfo() {
    return {
      title: 'Group detailpage',
      meta: [
        { property: 'og:title', content: this.group.name },
        { property: 'og:site_name', content: `WWSV ${this.group.name}` },
        { property: 'og:description', content: this.group.aboutUs !== undefined ? this.$options.filters.striphtml(this.group.aboutUs) : '' },
        { property: 'og:image', content: this.group.hasPicture ? this.group.profilePicture : require('@/assets/images/generic/temporary.png') },
        { property: 'twitter:card', content: 'summary_large_image' },
        { property: 'twitter:image', content: this.group.hasPicture ? this.group.profilePicture : require('@/assets/images/generic/temporary.png') },
        { property: 'twitter:image:alt', content: `WWSV ${this.group.name}` },
        { property: 'twitter:description', content: this.group.aboutUs !== undefined ? this.$options.filters.striphtml(this.group.aboutUs) : '' },
      ],
    };
  },
  name: 'GroupDetail',
  components: {
    [Challenge.name]: Challenge,
    [Sidebar.name]: Sidebar,
    [Map.name]: Map,
    [Activity.name]: Activity,
    [UserCard.name]: UserCard,
    [GroupFollowersAdmin.name]: GroupFollowersAdmin,
    [ShareModal.name]: ShareModal,
    [Advertisement.name]: Advertisement,
  },
  props: {},
  data: () => ({
    showShareModal: false,
    shareModalIsActive: false,
    activetab: 1,
    sidebarInfo: undefined,
    map: {
      zoom: 14,
      groups: [],
      center: [51.0, 3.0],
      showEvents: false,
    },
    Loaded: false,
    followers: {
      followerList: [],
      currentPage: 1,
      pageSize: 1000,
    },
    error: false,
    errorCover: false,
    showRightArrow: false,
    showLeftArrow: false,
    advertisement: null,
  }),
  computed: {
    ...mapGetters('advertisement', {
      bestMatch: 'getBestmatch',
    }),
    ...mapGetters('group', {
      group: 'getGroup',
      getFollowers: 'getGroupFollowers',
    }),
    ...mapGetters('challenge', {
      challengeList: 'getChallengeList',
    }),
  },
  methods: {
    ...mapActions('advertisement', {
      initialiseAdvertisementBestmatch: 'FETCH_DATA_ADVERTISEMENT_BESTMATCH',
    }),
    ...mapActions('group', {
      initialiseGroup: 'FETCH_DATA_GROUP',
      fetchGroupFollowers: 'FETCH_DATA_GROUP_FOLLOWERS_LIST',
      followGroup: 'FOLLOW_GROUP',
      unfollowGroup: 'UNFOLLOW_GROUP',
    }),
    ...mapMutations('group', {
      clearGroupFollowersList: 'CLEAR_GROUP_FOLLOWERS_LIST',
    }),
    ...mapActions('challenge', {
      initialiseChallengeList: 'FETCH_DATA_CHALLENGE_LIST',
    }),
    getGroupFollowers() {
      this.clearGroupFollowersList();
      const isAdmin = this.isAdmin();

      const paramsObj = {
        groupId: this.group.id,
        page: this.followers.currentPage,
        pageSize: this.followers.pageSize,
        isAdmin,
      };

      this.fetchGroupFollowers(paramsObj).then(() => {
        this.followers.followerList = this.getFollowers;
      });
    },
    getGroupDetails() {
      return new Promise((resolve, reject) => {
        this.initialiseGroup(this.$route.params.groupurl)
          .then(() => {
            const coordinates = [this.group.lat, this.group.long];
            this.error = false;
            this.map.groups = [];
            this.map.groups.push(this.group);
            this.map.showEvents = false;
            this.map.center = coordinates;
            this.Loaded = true;
            resolve();
          })
          .catch((err) => {
            if (err.response.status === 404) {
              this.$router.push({ name: '404' });
            } else {
              console.log(err);
              this.$toasted.show(...err.response.data);
            }
            reject();
          });
      });
    },
    followGroupAction() {
      if (this.$isLoggedIn) {
        this.followGroup(this.group.id).then(() => {
          this.$toasted.show(`Je volgt nu ${this.group.name}.`, { type: 'success' });
          this.getGroupDetails().then(() => {
            this.getGroupFollowers();
          });
        }).catch((error) => {
          this.$toasted.show(...error.response.data);
        });
      } else {
        const redirectPath = this.$route.query.redirect;
        this.$router.push(redirectPath ? { path: '/login', query: { redirect: redirectPath } } : '/login');
      }
    },
    unfollowGroupAction() {
      if (this.$isLoggedIn) {
        this.unfollowGroup(this.group.id).then(() => {
          this.$toasted.show(`Je volgt niet langer ${this.group.name}.`, { type: 'success' });
          this.getGroupDetails().then(() => {
            this.getGroupFollowers();
          });
        }).catch((error) => {
          this.$toasted.show(...error.response.data);
        });
      }
    },
    isAdmin() {
      return !!(this.$isAdmin || this.group.currentUserIsAdmin);
    },
    closeModal() {
      // the shareModalIsActive is catches the clickOutside event that is emitted when clicking "deel"
      if (this.shareModalIsActive) {
        this.shareModalIsActive = !this.shareModalIsActive;
      } else {
        this.showShareModal = false;
      }
    },
    handleScroll(event) {
      const containerWidth = this.$refs['scroll-container'].clientWidth;
      const { scrollWidth } = event.target;
      const maxScroll = parseInt(scrollWidth - containerWidth, 10);

      var scrollEnd = event.target.scrollLeft === maxScroll;
      var scrollBegin = event.target.scrollLeft === 0;

      if (scrollEnd) {
        this.showRightArrow = false;
      } else {
        this.showRightArrow = true;
      }

      if (scrollBegin) {
        this.showLeftArrow = false;
      } else {
        this.showLeftArrow = true;
      }
    },
  },
  serverPrefetch() {
    return this.initialiseGroup(this.$route.params.groupurl).catch((error) => {
      if (error.response.status === 404) {
        this.$router.push({ name: '404' });
      }
    });
  },
  created() {
    this.getGroupDetails().then(() => {
      if (this.$isLoggedIn) {
        this.getGroupFollowers();
      }
      const params = {
        tags: this.group.groupTags,
      };
      this.initialiseAdvertisementBestmatch(params)
        .then(() => {
          this.advertisement = this.bestMatch;
        });
    });
    if (!this.challengeList.length) this.initialiseChallengeList();
  },
  mounted() {
    setTimeout(() => {
      const containerWidth = this.$refs['scroll-container'].clientWidth;
      const { scrollWidth } = this.$refs['scroll-container'].children[0];
      const maxScroll = parseInt(scrollWidth - containerWidth, 10);

      if (maxScroll > 10) {
        this.showRightArrow = true;
      }
    }, 0);
  },
};
